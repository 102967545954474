import React from 'react';
import {
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import MeetingGuideImage from './meeting_guide.png';
import { Field, Form, Formik, FormikProps } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import * as Yup from 'yup';
import { IMeeting } from '../../../types/types';
import moment from 'moment/moment';
import { formatParsedISO } from '../../../utils/formatParsedISO';
import { CheckCircle, Unpublished } from '@mui/icons-material';
import { useEffectOnChange } from '../../../utils/useEffectOnChange';

interface FormType {
  publish: boolean;
  notify_on_updates: boolean;
  log_updates: boolean;
}

interface Props {
  onSubmit: (values: FormType) => void;
  saving?: boolean;
  meeting: IMeeting;
}

const schema = Yup.object().shape({
  publish: Yup.boolean(),
  notify_on_updates: Yup.boolean(),
  log_updates: Yup.boolean(),
});

export function MeetingSettings(props: Props) {
  const { onSubmit, saving, meeting } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const formRef = React.useRef<FormikProps<FormType>>();

  useEffectOnChange(
    () => {
      setDialogOpen(false);
      formRef.current?.setSubmitting(false);
    },
    saving,
    false,
  );

  const publishText = (
    <>
      This will send a link for the following meeting by email to all members of
      this Committee who are registered to attend:{' '}
      <Typography fontWeight={'bold'}>
        {meeting.name}, on{' '}
        {moment(meeting.date_start).format('ddd D MMMM Y [from] HH:mm')} to{' '}
        {moment(meeting.date_end).format('HH:mm')}
      </Typography>
    </>
  );

  return (
    <>
      <Button
        variant={'contained'}
        onClick={() => {
          setDialogOpen(true);
        }}
        color={meeting.published_at ? 'success' : 'warning'}
      >
        <Stack direction={'row'}>
          {meeting.published_at ? <CheckCircle /> : <Unpublished />}
          <Typography fontWeight={'bold'}>
            {meeting.published_at ? 'Published' : 'Draft'}
          </Typography>
          <Typography>Meeting settings</Typography>
        </Stack>
      </Button>

      <Formik<FormType>
        innerRef={formRef}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(true);
        }}
        initialValues={{
          publish: false,
          log_updates: meeting.log_updates || true,
          notify_on_updates: meeting.notify_on_updates || false,
        }}
        enableReinitialize
        validationSchema={schema}
      >
        {({ isSubmitting, values, errors }) => {
          return (
            <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              fullWidth
              maxWidth={'md'}
            >
              <Form>
                <DialogTitle>Meeting Settings</DialogTitle>
                <DialogContent>
                  <Stack>
                    {meeting.published_at ? (
                      <Typography>
                        Published on{' '}
                        {formatParsedISO(
                          meeting.published_at,
                          'dd/MM/yyyy HH:mm',
                        )}
                      </Typography>
                    ) : (
                      <>
                        <Typography>Meeting is in draft status</Typography>
                        <Field
                          type="checkbox"
                          edge="start"
                          component={CheckboxWithLabel}
                          Label={{
                            label: 'Publish meeting and notify attendees',
                          }}
                          name={'publish'}
                        />
                        {values.publish && publishText}
                      </>
                    )}
                    {(values.publish || meeting.published_at) && (
                      <>
                        <Field
                          type="checkbox"
                          edge="start"
                          component={CheckboxWithLabel}
                          Label={{
                            label: 'Save updates to the meeting log',
                          }}
                          name={'log_updates'}
                        />
                        <Field
                          type="checkbox"
                          edge="start"
                          component={CheckboxWithLabel}
                          Label={{
                            label:
                              'Notify attendees of updates to the meeting log',
                          }}
                          name={'notify_on_updates'}
                        />
                      </>
                    )}
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Button variant={'text'} onClick={() => setDialogOpen(false)}>
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isSubmitting || saving}>
                    Submit
                  </Button>
                </DialogActions>
              </Form>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
}
